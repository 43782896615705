import { NinetailedProvider } from '@ninetailed/experience.js-react'
import { NinetailedPreviewPlugin } from '@ninetailed/experience.js-plugin-preview'
import { NinetailedInsightsPlugin } from '@ninetailed/experience.js-plugin-insights'
import React, { useEffect, useState } from 'react'
import TrackPage from '@/components/track-page.tsx'

export default function NinetailedProviderWrapper({
  allExperiences,
  allAudiences,
  children,
  ninetailedClientId,
  ninetailedEnv,
}) {
  const [ninetailedReady, setNinetailed] = useState<any>()

  useEffect(() => {
    if (typeof window.ninetailed !== 'undefined') {
      setNinetailed(true)
    }
  }, [])
  return (
    <NinetailedProvider
      clientId={ninetailedClientId}
      environment="main"
      plugins={[
        new NinetailedInsightsPlugin(),

        ...(ninetailedEnv !== 'main'
          ? [
              new NinetailedPreviewPlugin({
                experiences: allExperiences || [],
                audiences: allAudiences || [],
              }),
            ]
          : []),
      ]}
    >
      {ninetailedReady === true && <TrackPage />}
      {children}
    </NinetailedProvider>
  )
}
