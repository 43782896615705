import { useEffect } from 'react'

import { useNinetailed } from '@ninetailed/experience.js-react'

export default function TrackPage() {
  const { page } = useNinetailed()

  useEffect(() => {
    if (typeof window.ninetailed !== 'undefined') {
      page()
    }
  }, [page])

  return null
}
