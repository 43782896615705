import { ExperienceMapper } from '@ninetailed/experience.js-utils'

import {
  ExperienceMapper as ExperienceMapperContentful,
  type ExperienceEntryLike,
  AudienceMapper,
  type AudienceEntryLike,
} from '@ninetailed/experience.js-utils-contentful'

export const mapNtExperiences = (experiences: any) => {
  if (!experiences || !experiences[0]) return []
  return (
    experiences &&
    experiences
      .map((experience: any) => ({
        id: experience.sys.id,
        name: experience.fields?.nt_name || null,
        type: experience.fields?.nt_type,
        components: experience.fields?.nt_config.components,
        config: experience.fields?.nt_config,
        trafficAllocation: experience.fields?.nt_config.traffic,
        audience: {
          id: experience.fields?.nt_audience?.fields?.nt_audience_id || null,
          name: experience.fields?.nt_audience?.fields?.nt_name || null,
        },
        variants:
          experience.fields?.nt_variants?.map((variant: any) => ({
            ...variant.fields,
            contentful_id: variant.sys.id,
            id: variant.sys.id,
          })) || 'no variants',
      }))
      .filter(ExperienceMapper.isExperienceEntry)
      .map(ExperienceMapper.mapExperience)
  )
}

export const mapNtContentfulExperiences = (experiences) => {
  return experiences
    ? experiences.filter(ExperienceMapperContentful.isExperienceEntry).map(ExperienceMapperContentful.mapExperience)
    : []
}

export const mapNtContentfulAudiences = (audiences: any) => {
  return audiences ? audiences.filter(AudienceMapper.isAudienceEntry).map(AudienceMapper.mapAudience) : []
}
